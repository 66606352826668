@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@500;600;700&display=swap');

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  display: none;
}
html {
  scroll-behavior: smooth;
}

body {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  font-family: 'Orbitron', sans-serif;
  background-color: #000;
  color: #fff;
  // width: 100%;
}

h3 {
  margin-bottom: 3vw;
  text-align: center;
  font-size: 2.2rem;
  letter-spacing: 0.2em;
}
